<!-- =========================================================================================
    File Name: StatisticsCard.vue
    Description: Statistics card component
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <vx-card class="overflow-hidden" :class="{'vx-card--clickable' : isClickable}">
        <div slot="no-body">
            <div class="vx-row items-center py-6 px-3 m-0">
                <div class="vx-col w-full md:w-3/5" :class="{'flex justify-between flex-row-reverse items-center': iconRight}">
                    <div class="vx-row">
                        <div class="vx-col w-2/3 md:w-full py-6">
                            <template v-if="icon">
                                <feather-icon :icon="icon" class="p-3 inline-flex rounded-full" :class="[`text-${color}`,   {'mb-4': !iconRight}]" :style="{background: `rgba(var(--vs-${color}),.15)`}"></feather-icon>
                            </template>
                            <div>
                                <h2 v-if="statisticOutOf" class="mb-1 font-bold">{{ statistic }} <small class="vx-card__smaller">/ {{ statisticOutOf }}</small></h2>
                                <h2 v-else class="mb-1 font-bold">{{ statistic }}</h2>
                                <span>{{ statisticTitle }}</span>
                            </div>
                        </div>
                        <div class="vx-col w-1/3 md:hidden text-right">
                            <template v-if="statisticBadgeType == 'alternate'">
                                <span class="vx-card__badge" :class="{'vx-card__badge--none': statisticBadge['direction'] == 'none', 'vx-card__badge--negative-alternate': statisticBadge['direction'] == 'down', 'vx-card__badge-alternate': statisticBadge['direction'] == 'up'}">{{ statisticBadge['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
                            </template>
                            <template v-else>
                                <span class="vx-card__badge" :class="{'vx-card__badge--none': statisticBadge['direction'] == 'none','vx-card__badge--negative': statisticBadge['direction'] == 'down'}">{{ statisticBadge['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
                            </template>
                        </div>
                    </div>
                </div>
                
                <div class="vx-col w-full md:w-2/5">
                    <div class="hidden md:block text-right">
                        <template v-if="statisticBadgeType == 'alternate'">
                            <span class="vx-card__badge" :class="{'vx-card__badge--none': statisticBadge['direction'] == 'none', 'vx-card__badge--negative-alternate': statisticBadge['direction'] == 'down', 'vx-card__badge-alternate': statisticBadge['direction'] == 'up'}">{{ statisticBadge['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
                        </template>
                        <template v-else>
                            <span class="vx-card__badge" :class="{'vx-card__badge--none': statisticBadge['direction'] == 'none', 'vx-card__badge--negative': statisticBadge['direction'] == 'down'}">{{ statisticBadge['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
                        </template>
                    </div>
                    <div class="line-area-chart pointer-events-none h-16 mt-4" :id="statsData.id">
                        <vue-apex-charts ref="apexChart" :type="type" height='100%' :options="computedChartOptions" :series="[{ name: this.chartData['name'], data: this.chartData['graph'] }]"></vue-apex-charts>
                    </div>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default{
        props: {
            icon: {
                type: String
            },
            statistic: {
                type: [Number, String],
                required: true,
            },
            statisticOutOf: {
                type: [Number, String]
            },
            statisticTitle: {
                type: String
            },
            statisticBadge: {
                type: Object,
            },
            statisticBadgeType: {
                type: String,
            },
            chartData: {
                type: Object,
                required: true
            },
            color: {
                type: String,
                default: 'primary',
            },
            chartType: {
                type: String,
                default: 'line-chart',
            },
            type: {
                type: String,
                default: 'line'
            },
            iconRight: {
                type: Boolean,
                default: false
            },
            isClickable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                statsData: {
                    series: [{
                        name: 'Chart Name',
                        data: [28, 40, 36, 52, 38, 60, 55]
                    }],
                },
            }
        },
        watch: {
            
        },
        computed: {
			computedChartOptions: function(){
                let color = this.color;

                return {
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 1.5
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.9,
                            opacityFrom: 0.7,
                            opacityTo: 0.5,
                            stops: [0, 98, 100]
                        }
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: this.getHex(color),
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        }
                    },
                    xaxis: {
                        type: 'numeric',
                        lines: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        labels: { show: false }
                    },
                    yaxis: [{
                        y: 0,
                        offsetX: 0,
                        offsetY: 0,
                        padding: { left: 0, right: 0 },
                    }],
                    tooltip: {
                        x: { show: false }
                    },
                }
            }
        },
        methods: {
            getHex() {
                let rgb  = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${this.color}`);
                rgb = rgb.split(",");
                return "#" + ((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1);
            },
        },
        components: {
            VueApexCharts
        },
    }
</script>