<template>
    <vx-card>
        <div slot="no-body">
            <div class="vx-card__header">
                <div class="vx-card__title">
                    <h4>{{ title }}</h4>
                </div>
                <i v-if="flags['isChartDrilldown']" class="inline-block vs-icon feather icon-refresh-cw cursor-pointer" @click="chartAbuseDrillUp()"></i>
            </div>
            <div class="flex justify-center p-6" :class="{'invisible': !flags['isChartPopulated'], 'pointer-events-none': !flags['isChartPopulated']}">
                <highcharts ref="chartRef" class="w-full" :options="chartOptions" :updateArgs="[true, true, true]"></highcharts>
            </div>
        </div>
    </vx-card>
</template>

<script>
	import Highcharts from 'highcharts'
	import drilldownInit from 'highcharts/modules/drilldown'
	import {Chart} from 'highcharts-vue'

	drilldownInit(Highcharts)

    export default{
        props: {
            title: {
                type: String,
                required: true,
            },
            seriesName: {
                type: String,
                required: true,
            },
            series: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        type: 'pie',
                        style: {
                            fontFamily: 'Open Sans, sans-serif'
                        },
                        events: {
                            drilldown: () => {
                                this.flags['isChartDrilldown'] = true;
                            }
                        },
                    },
                    colors: ['#34568B', '#416FA9', '#377CC9', '#4E94D9', '#489BE6', '#33A2FF', '#85D9FA', '#B1F4E4', '#99E7D5', '#80D9C5', '#68CBB6', '#37B798', '#35A084', '#ECECEC', '#DEDFDF', '#CECFCF', '#BBBBBB', '#A4A4A4', '#949494', '#868686'],
                    title: {
                        text: undefined
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.y}%'
                            }
                        },
                        pie: {
                            size: "75%"
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}%</b> of total abuse<br/>'
                    },
                    series: [
                        {
                        name: "Series",
                        colorByPoint: true,
                        data: [
                            {
                                name: "A",
                                y: 25,
                                drilldown: "A"
                            },
                            {
                                name: "B",
                                y: 25,
                                drilldown: "B"
                            },
                            {
                                name: "C",
                                y: 25,
                                drilldown: "C"
                            },
                            {
                                name: "D",
                                y: 25,
                                drilldown: "D"
                            },
                        ]
                        }
                    ],
                    drilldown: {
                        series: [
                        {
                            name: "A",
                            id: "A",
                            data: [
                            [
                                "First",
                                50
                            ],
                            [
                                "Second",
                                50
                            ],
                            ]
                        },
                        {
                            name: "B",
                            id: "B",
                            data: [
                            [
                                "First",
                                50
                            ],
                            [
                                "Second",
                                50
                            ],
                            ]
                        },
                        {
                            name: "C",
                            id: "C",
                            data: [
                            [
                                "First",
                                50
                            ],
                            [
                                "Second",
                                50
                            ],
                            ]
                        },
                        {
                            name: "D",
                            id: "D",
                            data: [
                            [
                                "First",
                                50
                            ],
                            [
                                "Second",
                                50
                            ],
                            ]
                        },
                        ]
                    }
                },
                flags: {
                    isChartPopulated: false,
                    isChartDrilldown: false
                }
            }
        },
        watch: {
            series: function(val) {
                // Populate Chart Series
                this.populateSeries(val);
            }
        },
        computed: {
            
        },
        methods: {
			chartAbuseDrillUp() {
				this.$refs.chartRef.chart.drillUp();
				this.flags['isChartDrilldown'] = false;
            },
            populateSeries(val) {
                if(val.length) {
                    // Iterate Prop
                    let series = val.map(x => {
                        if(x['color']) {
                            return {
                                name: x['name'],
                                y: x['y'],
                                color: x['color'],
                                drilldown: x['drilldown'] ? x['name'] : null
                            }
                        }
                        else {
                            return {
                                name: x['name'],
                                y: x['y'],
                                drilldown: x['drilldown'] ? x['name'] : null
                            }
                        }
                    });
                    // Assign to Chart Series
                    this.chartOptions['series'][0]['name'] = this.seriesName;
                    this.chartOptions['series'][0]['data'] = series;
                    // Populate Drilldown
                    this.populateDrilldown(val);
                    // Set Populated Flag
                    this.flags['isChartPopulated'] = true;
                }
                else {
                    this.resetSeries();
                    // Unset Populated Flag
                    this.flags['isChartPopulated'] = false;
                }
            },
            populateDrilldown(val) {
                if(val) {
                    // Iterate Prop
                    let drilldown = val.filter(x => x['drilldown']).map(x => {
                        return {
                            name: x['name'],
                            id: x['name'],
                            data: x['drilldown']
                        }
                    });
                    // Assign to Chart Drilldown
                    this.chartOptions['drilldown']['series'] = drilldown;
                }
            },
            resetSeries() {
                // Reset Chart Series
                this.chartOptions['series'][0]['name'] = "Series";
                this.chartOptions['series'][0]['data'] = [
                    {
                        name: "A",
                        y: 25,
                        drilldown: "A"
                    },
                    {
                        name: "B",
                        y: 25,
                        drilldown: "B"
                    },
                    {
                        name: "C",
                        y: 25,
                        drilldown: "C"
                    },
                    {
                        name: "D",
                        y: 25,
                        drilldown: "D"
                    },
                ];
                // Reset Chart Drilldown
                this.chartOptions['drilldown']['series'] = [
                    {
                        name: "A",
                        id: "A",
                        data: [
                        [
                            "First",
                            50
                        ],
                        [
                            "Second",
                            50
                        ],
                        ]
                    },
                    {
                        name: "B",
                        id: "B",
                        data: [
                        [
                            "First",
                            50
                        ],
                        [
                            "Second",
                            50
                        ],
                        ]
                    },
                    {
                        name: "C",
                        id: "C",
                        data: [
                        [
                            "First",
                            50
                        ],
                        [
                            "Second",
                            50
                        ],
                        ]
                    },
                    {
                        name: "D",
                        id: "D",
                        data: [
                        [
                            "First",
                            50
                        ],
                        [
                            "Second",
                            50
                        ],
                        ]
                    },
                ]
            },
        },
        components: {
			highcharts: Chart
        },
        mounted() {
            // Populate Chart Series
            this.populateSeries(this.series);
        },
    }
</script>