<template>
    <vx-card title="Data Summary">
        <div slot="no-body" class="px-6 pt-6 md:pt-0">
            <div class="vx-row m-0">
                <div class="vx-col w-full md:w-1/4 xxl:1/5 flex justify-center flex-col">
                    <div class="pb-12 w-full border-0 border-b border-solid border-grey">
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold text-primary flex items-center">
                                <span class="inline-block">{{ dataSummaryInfo['totalRegistrations'] | formatNumber }}</span>
                            </h2>
                            <span class="opacity-75">Total Registrations</span>
                        </div>
                        <h6 class="mb-3">{{ $moment(dateRange['start'], "YYYY-MM-DD").format('ll') }} to {{ $moment(dateRange['end'], "YYYY-MM-DD").format('ll') }}</h6>
                        <div>
                            <div class="inline-flex items-center mr-2"><span class="opacity-75">Abuse Report:</span></div>
                            <h6 class="inline-block text-warning">{{ dataSummaryInfo['abuseReport'] | formatNumber }}</h6>
                        </div>
                        <div>
                            <div class="inline-flex items-center mr-2"><span class="opacity-75">Abuse Rate:</span></div>
                            <h6 class="inline-block text-danger">{{ dataSummaryInfo['abuseRate'] | formatNumber }}%</h6>
                        </div>
                    </div>
                    <div class="pt-5 vx-row">
                        <div class="vx-col w-full md:w-1/2 flex items-center pb-2"><div class="h-3 w-3 rounded-full mr-2 bg-primary"></div><small class="opacity-75">New Registrations</small></div>
                        <div class="vx-col w-full md:w-1/2 flex items-center pb-2"><div class="h-3 w-3 rounded-full mr-2 bg-warning"></div><small class="opacity-75">Abuse Reported</small></div>
                        <div class="vx-col w-full md:w-1/2 flex items-center pb-2"><div class="h-3 w-3 rounded-full mr-2 bg-danger"></div><small class="opacity-75">Abusive Registrations</small></div>
                    </div>
                </div>
                <div class="vx-col w-full md:w-3/4 xxl:4/5">
                    <vue-apex-charts ref="dataSummaryChart" type="line" height="350" :options="computedChartOptions" :series="chartData.series"></vue-apex-charts>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
	import VueApexCharts from 'vue-apexcharts'
	
	const themeColors = ['#008BFF', '#FCB414', '#F05540']

    export default{
        props: {
            series: {
                type: Object,
                required: true,
            },
            totalRegistrations: {
                type: [Number, String],
                required: true,
            },
            abuseReport: {
                type: [Number, String],
                required: true,
            },
            abuseRate: {
                type: [Number, String],
                required: true,
            },
            dateRange: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                chartData: {
                    series: [{
                            name: 'New Registrations',
                            type: 'column',
                            data: [0, 0, 0, 0, 0, 0, 0, 0]
                        }, {
                            name: 'Abuse Reported',
                            type: 'line',
                            data: [0, 0, 0, 0, 0, 0, 0, 0]
                        }, {
                            name: 'Abusive Registrations',
                            type: 'line',
                            data: [0, 0, 0, 0, 0, 0, 0, 0]
                    }],
                },
				selectionPoints: null,
            }
        },
        watch: {
            series: {
                handler: function(val) {
                    // Populate Chart Series
                    this.populateSeries(val);
                },
                deep: true
            },
        },
        computed: {
			dataSummaryInfo: function(){
				if(this.selectionPoints != null) {
                    let abuseRate = (this.chartData['series'][2]['data'][this.selectionPoints] / this.chartData['series'][0]['data'][this.selectionPoints]) * 100;

					return {
						totalRegistrations: this.chartData['series'][0]['data'][this.selectionPoints],
						abuseReport: this.chartData['series'][1]['data'][this.selectionPoints],
						abuseRate: this.chartData['series'][0]['data'][this.selectionPoints] != 0 ? abuseRate.toFixed(2) : 0,
					};
				}
				else {
					return {
						totalRegistrations: this.totalRegistrations,
						abuseReport: this.abuseReport,
						abuseRate: this.abuseRate,
					};
				}
			},
			computedChartOptions: function(){
                let labels = this.dateRange['labels'] ? this.dateRange['labels'] : ['0','0','0','0','0','0','0','0'];

				return {
                    colors: themeColors,
                    chart: {
                        stacked: false,
                        fontFamily: 'Open Sans, sans-serif',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        },
                        events: {
                            dataPointSelection: (event, chartContext, config) => {
                                this.setDataSummaryPointSelection(config['dataPointIndex']);
                            }
                        }
                    },
                    stroke: {
                        width: [0, 2, 2],
                        curve: 'smooth',
                    },
                    fill: {
                        opacity: 1,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '25%'
                        }
                    },
                    labels: labels,
                    markers: {
                        size: 0
                    },
                    yaxis: [
                    {
                        seriesName: 'New Registrations',
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                        },
                        title: {
                            text: "New Registrations",
                        },
                    },
                    {
                        seriesName: 'Abuse Reported',
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                        },
                        title: {
                            text: "Abuse Reported",
                        }
                    },
                    {
                        seriesName: 'Abuse Reported',
                        show: false,
                    }
                    ],
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        followCursor: false,
                        y: {
                            formatter: function(y) {
                                if (typeof y !== "undefined") {
                                    return y.toFixed(0) + " domains";
                                }
                                return y;

                            }
                        }
                    },
                    
                };
			},
        },
        methods: {
			setDataSummaryPointSelection(point) {
				if(this.selectionPoints == point) {
					this.clearDataSummaryPointSelection();
				}
				else {
					this.selectionPoints = point;
				}
			},
			clearDataSummaryPointSelection() {
				this.selectionPoints = null;
			},
            populateSeries(val) {
                if(val) {
                    // Init Graph Data
                    this.chartData['series'][0]['data'] = val['newRegistrations'];
                    this.chartData['series'][1]['data'] = val['abuseReported'];
                    this.chartData['series'][2]['data'] = val['abuseRegistrations'];
                }
                else {
                    this.resetSeries();
                }
            },
            resetSeries() {
                // Reset Graph Data
                this.chartData['series'][0]['data'] = [0, 0, 0, 0, 0, 0, 0, 0];
                this.chartData['series'][1]['data'] = [0, 0, 0, 0, 0, 0, 0, 0];
                this.chartData['series'][2]['data'] = [0, 0, 0, 0, 0, 0, 0, 0];
            },
        },
        components: {
			VueApexCharts
        },
        mounted() {
            // Populate Chart Series
            this.populateSeries(this.series);
        },
    }
</script>