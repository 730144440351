<template>
	<div id="dashboard-analytics">

		<div class="flex flex-wrap items-center mb-6">
			<div class="content-area__heading pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light">
				<h2 class="mb-1">Overview</h2>
			</div>
			<div class="vx-breadcrumb ml-4 md:block hidden">
				<ul class="flex flex-wrap items-center">
					<li class="inline-flex"><span class="cursor-default">{{ $moment(dashboardStats['dateRange']['start'], "YYYY-MM-DD").format('ll') }} to {{ $moment(dashboardStats['dateRange']['end'], "YYYY-MM-DD").format('ll') }}</span><span v-if="isFilterApplied" class="badge badge--primary ml-3">filter applied<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer" @click="refreshPage()"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></span></li>
				</ul>
			</div>
			<div class="ml-auto flex flex-wrap items-center justify-between">
				<vs-button type="border" class="mr-4 md:block" @click="$root.$emit('toggleGlobalFilters')">Filter</vs-button>
				<vs-button type="border" icon="icon-refresh-cw" icon-pack="feather" class="md:block" @click="refreshPage()"></vs-button>
			</div>
		</div>
		
		<div class="vx-row">

			<!-- Statistics Card -->
            <div class="vx-col w-full xl:w-3/12 mb-base">
                <statistics-card-line :statistic="dashboardStats['totalRegistrations']['stats'] | formatNumber" :statisticTitle="'Total Registrations'" :statisticBadge="dashboardStats['totalRegistrations']['deviation']" :chartData="{ name: 'Total Registrations', graph: dashboardStats['totalRegistrations']['graph'] }" :color="getDeviationColor(dashboardStats['totalRegistrations']['deviation']['direction'])" type='area' @click.native="$router.push({ path: '/new-registrations' })" is-clickable></statistics-card-line>
            </div>

			<!-- Statistics Card -->
            <div class="vx-col w-full xl:w-3/12 mb-base">
                <statistics-card-line :statistic="`${dashboardStats['abuseRate']['stats']}%`" :statisticOutOf="dashboardStats['abuseRate']['statsOutOf'] | formatNumber" :statisticTitle="'Abuse Rate'" :statisticBadge="dashboardStats['abuseRate']['deviation']" statisticBadgeType="alternate" :chartData="{ name: 'Abuse Rate', graph: dashboardStats['abuseRate']['graph'] }" :color="getDeviationColor(dashboardStats['abuseRate']['deviation']['direction'],'alternate')" type='area' @click.native="$router.push({ path: '/abuse-reported' })" is-clickable></statistics-card-line>
            </div>

			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-4/12 mb-base">
				<vx-card class="overflow-hidden flex items-center content-stretch h-full">
					<div slot="no-body">
						<div class="vx-row m-0 h-full">
							<div class="vx-col py-6 w-full md:w-1/2 px-3 vx-card--clickable" @click="filterProactiveTakedowns()">
								<div class="vx-row m-0">
									<div class="vx-col w-1/2 pt-6">
										<h2 class="mb-1 font-bold">{{ dashboardStats['proactiveTakedowns']['stats'] | formatNumber }}</h2>
									</div>
									<div class="vx-col w-1/2">
										<div class="text-right">
											<span class="vx-card__badge" :class="{'vx-card__badge--negative-alternate': dashboardStats['proactiveTakedowns']['deviation']['direction'] == 'down', 'vx-card__badge-alternate': dashboardStats['proactiveTakedowns']['deviation']['direction'] == 'up', 'vx-card__badge--none': dashboardStats['proactiveTakedowns']['deviation']['direction'] == 'none'}">{{ dashboardStats['proactiveTakedowns']['deviation']['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
										</div>
									</div>
									<div class="vx-col w-full pb-6">
										<span>Proactive Takedowns</span>
									</div>
								</div>
							</div>
							<div class="vx-col py-6 w-full md:w-1/2 px-3 vx-card__bordered vx-card--clickable" @click="$router.push({ path: '/unsuspension-list' })">
								<div class="vx-row m-0">
									<div class="vx-col w-1/2 pt-6">
										<h2 class="mb-1 font-bold">{{ dashboardStats['unsuspensions']['stats'] | formatNumber }}</h2>
									</div>
									<div class="vx-col w-1/2">
										<div class="text-right">
											<span class="vx-card__badge" :class="{'vx-card__badge--negative-alternate': dashboardStats['unsuspensions']['deviation']['direction'] == 'down', 'vx-card__badge-alternate': dashboardStats['unsuspensions']['deviation']['direction'] == 'up', 'vx-card__badge--none': dashboardStats['unsuspensions']['deviation']['direction'] == 'none'}">{{ dashboardStats['unsuspensions']['deviation']['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
										</div>
									</div>
									<div class="vx-col w-full pb-6">
										<span>Unsuspensions</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</vx-card>
			</div>

			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-2/12 mb-base">
				<vx-card class="overflow-hidden py-6 flex items-center content-stretch h-full vx-card--clickable" @click.native="isAbuseReportFiltered = !isAbuseReportFiltered">
					<div slot="no-body">
						<div class="vx-row px-3 m-0">
							<div class="vx-col w-1/2 pt-6">
								<h2 class="mb-1 font-bold">{{ dashboardStats['abuseReport']['stats'] | formatNumber }}</h2>
							</div>
							<div class="vx-col w-1/2">
								<div class="text-right">
									<span class="vx-card__badge" :class="{'vx-card__badge--negative-alternate': dashboardStats['abuseReport']['deviation']['direction'] == 'down', 'vx-card__badge-alternate': dashboardStats['abuseReport']['deviation']['direction'] == 'up', 'vx-card__badge--none': dashboardStats['abuseReport']['deviation']['direction'] == 'none'}">{{ dashboardStats['abuseReport']['deviation']['value'] }}%<svg viewBox="0 0 17 17"><path fill="none" fill-rule="evenodd" stroke="#43BFA4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.76" d="M2 15L14 3H3.944M14.5 3v10"/></svg></span>
								</div>
							</div>
							<div class="vx-col w-full pb-6 flex justify-between items-center">
								<span>Abuse Report</span>
								<feather-icon icon="ChevronDownIcon" class="h-5" svgClasses="h-full" :class="{'rotate180': isAbuseReportFiltered}"/>
							</div>
						</div>
					</div>
				</vx-card>
			</div>

			<template v-if="isAbuseReportFiltered">
				<!-- Statistics Card -->
				<div class="vx-col w-full xl:w-3/12 mb-base">
					<vx-card slot="no-body" class="vx-card--clickable" @click.native="filterAbuseReport('A')">
						<div class="px-1 flex justify-between flex-row-reverse items-center">
							<small class="w-10 h-10 inline-flex items-center justify-center rounded-full text-primary" style="background: rgba(var(--vs-primary),0.1);">A</small>
							<div>
								<h5 class="mb-1 font-bold">{{ dashboardStats['abuseReportStatuses']['active'] | formatNumber }}</h5>
								<span>Active Domains</span>
							</div>
						</div>
					</vx-card>
				</div>
				<!-- Statistics Card -->
				<div class="vx-col w-full xl:w-3/12 mb-base">
					<vx-card slot="no-body" class="vx-card--clickable" @click.native="filterAbuseReport('SHP')">
						<div class="px-1 flex justify-between flex-row-reverse items-center">
							<small class="w-10 h-10 inline-flex items-center justify-center rounded-full text-primary" style="background: rgba(var(--vs-primary),0.1);">SHP</small>
							<div>
								<h5 class="mb-1 font-bold">{{ dashboardStats['abuseReportStatuses']['serverHoldInProgress'] | formatNumber }}</h5>
								<span>SH In Progress</span>
							</div>
						</div>
					</vx-card>
				</div>
				<!-- Statistics Card -->
				<div class="vx-col w-full xl:w-3/12 mb-base">
					<vx-card slot="no-body" class="vx-card--clickable" @click.native="filterAbuseReport('SH')">
						<div class="px-1 flex justify-between flex-row-reverse items-center">
							<small class="w-10 h-10 inline-flex items-center justify-center rounded-full text-primary" style="background: rgba(var(--vs-primary),0.1);">SH</small>
							<div>
								<h5 class="mb-1 font-bold">{{ dashboardStats['abuseReportStatuses']['serverHold'] | formatNumber }}</h5>
								<span>Server Hold</span>
							</div>
						</div>
					</vx-card>
				</div>
				<!-- Statistics Card -->
				<div class="vx-col w-full xl:w-3/12 mb-base">
					<vx-card slot="no-body" class="vx-card--clickable" @click.native="filterAbuseReport('D')">
						<div class="px-1 flex justify-between flex-row-reverse items-center">
							<small class="w-10 h-10 inline-flex items-center justify-center rounded-full text-primary" style="background: rgba(var(--vs-primary),0.1);">D</small>
							<div>
								<h5 class="mb-1 font-bold">{{ dashboardStats['abuseReportStatuses']['deleted'] | formatNumber }}</h5>
								<span>Deleted Domains</span>
							</div>
						</div>
					</vx-card>
				</div>
			</template>

			<!-- Statistics Card -->
			<div class="vx-col w-full mb-base">
				<vx-card class="overflow-hidden flex items-center content-stretch h-full text-center">
					<div slot="no-body">
						<div class="vx-row m-0">
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('site')}" @click="filterTLD('site')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['site']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['site']['abuseRate'])" :color="dashboardStats['tldBreakdown']['site']['abuseRate'] > 4 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['site']['totalRegistrations'] | formatNumber }}</small>
											<span>.site</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('website')}" @click="filterTLD('website')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['website']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['website']['abuseRate'])" :color="dashboardStats['tldBreakdown']['website']['abuseRate'] > 4 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['website']['totalRegistrations'] | formatNumber }}</small>
											<span>.website</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('store')}" @click="filterTLD('store')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['store']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['store']['abuseRate'])" :color="dashboardStats['tldBreakdown']['store']['abuseRate'] > 1 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['store']['totalRegistrations'] | formatNumber }}</small>
											<span>.store</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('online')}" @click="filterTLD('online')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['online']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['online']['abuseRate'])" :color="dashboardStats['tldBreakdown']['online']['abuseRate'] > 4 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['online']['totalRegistrations'] | formatNumber }}</small>
											<span>.online</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('tech')}" @click="filterTLD('tech')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['tech']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['tech']['abuseRate'])" :color="dashboardStats['tldBreakdown']['tech']['abuseRate'] > 1 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['tech']['totalRegistrations'] | formatNumber }}</small>
											<span>.tech</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('press')}" @click="filterTLD('press')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['press']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['press']['abuseRate'])" :color="dashboardStats['tldBreakdown']['press']['abuseRate'] > 6 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['press']['totalRegistrations'] | formatNumber }}</small>
											<span>.press</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('host')}" @click="filterTLD('host')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['host']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['host']['abuseRate'])" :color="dashboardStats['tldBreakdown']['host']['abuseRate'] > 5 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['host']['totalRegistrations'] | formatNumber }}</small>
											<span>.host</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('fun')}" @click="filterTLD('fun')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['fun']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['fun']['abuseRate'])" :color="dashboardStats['tldBreakdown']['fun']['abuseRate'] > 5 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['fun']['totalRegistrations'] | formatNumber }}</small>
											<span>.fun</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('space')}" @click="filterTLD('space')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['space']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['space']['abuseRate'])" :color="dashboardStats['tldBreakdown']['space']['abuseRate'] > 5 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['space']['totalRegistrations'] | formatNumber }}</small>
											<span>.space</span>
										</div>
									</div>
								</div>
							</div>
							<div class="vx-col w-full md:w-1/10 py-6 px-3 vx-card__bordered vx-card--clickable" :class="{'greyscale' : dashboardStats['tld'] && !dashboardStats['tld'].includes('uno')}" @click="filterTLD('uno')">
								<div class="vx-row m-0">
									<div class="vx-col w-full">
										<h5>{{ dashboardStats['tldBreakdown']['uno']['abuseRate'] }}%</h5>
										<vs-progress class="my-1" :height="3" :percent="Math.round(dashboardStats['tldBreakdown']['uno']['abuseRate'])" :color="dashboardStats['tldBreakdown']['uno']['abuseRate'] > 5 ? 'danger' : 'success'"></vs-progress>
										<div>
											<small class="vx-card__small block">{{ dashboardStats['tldBreakdown']['uno']['totalRegistrations'] | formatNumber }}</small>
											<span>.uno</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</vx-card>
			</div>

			<!-- Anomalies Card -->
			<div class="vx-col w-full mb-base">
				<vx-card :class="{'greyscale' : isAnomaliesGreyscale}">
					<div slot="no-body">
						<div class="vx-card__header">
							<div class="vx-card__title">
								<h4>Latest Anomalies</h4>
							</div>
							<small class="inline-block cursor-pointer" @click="viewAllAnomalies()">View All</small>
						</div>
						<div class="p-6 pt-0">
							<vs-table class="dash-table" :data="anomalies">
								<template slot="thead">
									<vs-th>Date</vs-th>
									<vs-th>Spike</vs-th>
									<vs-th>Anomaly</vs-th>
									<vs-th></vs-th>
									<vs-th class="td-check"></vs-th>
								</template>
								<template slot-scope="{data}">
									<vs-tr :key="indextr" v-for="(tr, indextr) in data">
										<vs-td :data="data[indextr].date">
											<div class="inline-block w-24 md:w-auto">{{ data[indextr].date }}</div>
										</vs-td>
										<vs-td :data="data[indextr].type">
											<div v-if="data[indextr].type == 'source'" class="badge badge--danger">source</div>
											<div v-if="data[indextr].type == 'registration'" class="badge badge--primary">registration</div>
											<div v-if="data[indextr].type == 'talos'" class="badge badge--warning">talos</div>
										</vs-td>
										<vs-td :data="data[indextr].anomaly">
											<div class="flex items-center"><div class="inline-block w-64 md:w-auto" v-html="data[indextr].anomaly"></div><strong v-if="data[indextr].deviation" class="ml-2"> - {{ data[indextr].deviation }}%<feather-icon icon="ArrowUpIcon" class="h-5" svgClasses="h-full fill-current text-danger"/></strong></div>
										</vs-td>
										<vs-td :data="data[indextr].filters" class="text-right">
											<vs-button color="#626262" type="border" size="small" @click="viewAnomaly(data[indextr].filter, data[indextr].type)">View Domains</vs-button>
										</vs-td>
										<vs-td class="td-check"></vs-td>
									</vs-tr>
								</template>
							</vs-table>
						</div>
					</div>
				</vx-card>
			</div>

			<!-- Mixed Chart Card -->
            <div class="vx-col w-full mb-base">
				<data-summary-chart :series="chartData['dataSummary']" :totalRegistrations="this.dashboardStats['totalRegistrations']['stats']" :abuseReport="this.dashboardStats['abuseReport']['stats']" :abuseRate="this.dashboardStats['abuseRate']['stats']" :dateRange="dashboardStats['dateRange']"/>
            </div>

			<!-- Pie Chart Card -->
            <div class="vx-col w-full xl:w-1/2 mb-base">
				<abuse-pie-chart title="Abuse Contribution By Registrar" :series="chartData['registrarAbuse']" seriesName="Registrars" :class="{'greyscale' : isPieChartGreyscale}"/>
            </div>

			<!-- Pie Chart Card -->
            <div class="vx-col w-full xl:w-1/2 mb-base">
				<abuse-pie-chart title="Abuse Contribution By TLD" :series="chartData['tldAbuse']" seriesName="TLDs" :class="{'greyscale' : isPieChartGreyscale}"/>
            </div>

		</div>
	</div>
</template>

<script>
	import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
	import AbusePieChart from '@/components/statistics-charts/AbusePieChart.vue'
	import DataSummaryChart from '@/components/statistics-charts/DataSummaryChart.vue'

	export default {
		components: {
			StatisticsCardLine,
			AbusePieChart,
			DataSummaryChart,
		},
		data() {
			return {
				dashboardStats: {
					dateView: 'weekly',
					dateRange: {
						start: new Date(),
						end: new Date(),
						labels: ['1','2','3','4','5','6','7','8']
					},
					tld: null,
					registrar: null,
					totalRegistrations: {
						name: 'Total Registrations',
						stats: 0,
						deviation: {
							direction: 'none',
							value: 0
						},
						graph: [0, 0, 0, 0, 0, 0, 0, 0],
					},
					abuseRate: {
						name: 'Abuse Rate',
						stats: 0,
						statsOutOf: 0,
						deviation: {
							direction: 'none',
							value: 0
						},
						graph: [0, 0, 0, 0, 0, 0, 0, 0],
					},
					proactiveTakedowns: {
						name: 'Proactive Takedowns',
						stats: 0,
						deviation: {
							direction: 'none',
							value: 0
						},
					},
					unsuspensions: {
						name: 'Unsuspensions',
						stats: 0,
						deviation: {
							direction: 'none',
							value: 0
						},
					},
					abuseReport: {
						name: 'Abuse Report',
						stats: 0,
						deviation: {
							direction: 'none',
							value: 0
						},
						graph: [0, 0, 0, 0, 0, 0, 0, 0],
					},
					abuseReportStatuses: {
						active: 0,
						serverHold: 0,
						serverHoldInProgress: 0,
						deleted: 0,
					},
					tldBreakdown: {
						site: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						website: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						store: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						online: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						tech: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						press: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						host: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						fun: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						space: {
							abuseRate: 0,
							totalRegistrations: 0
						},
						uno: {
							abuseRate: 0,
							totalRegistrations: 0
						}
					}
				},
				anomalies: [],
				chartData: {
					dataSummary: {
						newRegistrations: [0, 0, 0, 0, 0, 0, 0, 0],
						abuseReported: [0, 0, 0, 0, 0, 0, 0, 0],
						abuseRegistrations: [0, 0, 0, 0, 0, 0, 0, 0],
					},
					registrarAbuse: [],
					tldAbuse: []
				},
				isFilterApplied: false,
				filtersApplied: null,
				isAbuseReportFiltered: false,
				dataFetched: {
					stats: false,
					anomalies: false,
					charts: false
				}
			}
		},
		watch: {
			'dashboardStats.totalRegistrations.graph': function(val){
				this.populateDataSummary('newRegistrations', val);
			},
			'dashboardStats.abuseReport.graph': function(val){
				this.populateDataSummary('abuseReported', val);
			},
			'dashboardStats.abuseRate.graph': function(val){
				this.populateDataSummary('abuseRegistrations', val);
			},
			dataFetched: {
				handler: function(val) {
					if(val['stats'] && val['anomalies'] && val['charts'] ) {
						// Hide Loader
						this.hideBackgroundLoader();
					}
				},
				deep: true
			}
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			isTLDGreyscale: function(){
				return (this.dashboardStats['tld'] && this.dashboardStats['registrar'] == null) && (this.filtersApplied != null && this.filtersApplied['dateView'] == 'weekly' && this.filtersApplied['customWeek'] == null);
			},
			isAnomaliesGreyscale: function(){
				return (this.dashboardStats['tld'] == null && this.dashboardStats['registrar'] == null) && (this.filtersApplied != null && ((this.filtersApplied['dateView'] == 'weekly' && this.filtersApplied['customWeek'] != null) || this.filtersApplied['dateView'] != 'weekly'));
			},
			isPieChartGreyscale: function(){
				return (this.dashboardStats['tld'] != null || this.dashboardStats['registrar'] != null) && (this.filtersApplied != null && this.filtersApplied['dateView'] == 'weekly' && this.filtersApplied['customWeek'] == null);
			},
		},
		methods: {
			viewAllAnomalies() {
				this.$router.push({ path: '/anomalies' })
			},
			showBackgroundLoader() {
				// Show Loader
				this.$vs.loading();
			},
			hideBackgroundLoader() {
				setTimeout(() => {
					// Hide Loader
					this.$vs.loading.close();
					// Reset Data Fetched Flags
					this.dataFetched['stats'] = false;
					this.dataFetched['anomalies'] = false;
					this.dataFetched['charts'] = false;
				}, 100);
			},
			populateDashboardData(globalFilters) {
				// Show Loader
				this.showBackgroundLoader();
				// Assign Filters
				let filters = globalFilters ? globalFilters : {
					dateView: 'weekly',
                    startDate: null,
                    endDate: null,
					tld: null,
					registrar: null,
				};
				// Get Stats Data
				this.$axiosSecure.get('/dashboard-stats', {
					params: filters
				})
				.then((response) => {
					this.dashboardStats = response.data;
					this.dataFetched['stats'] = true;
				})
				.catch((error) => {
					this.dataFetched['stats'] = true;
					console.log(error)
				});
				// Get Anomalies Data
				this.$axiosSecure.get('/get-anomalies', {
					params: filters
				})
				.then((response) => {
					this.anomalies = response.data;
					this.dataFetched['anomalies'] = true;
				})
				.catch((error) => {
					this.dataFetched['anomalies'] = true;
					console.log(error)
				});
				// Get Pie Charts Data
				this.$axiosSecure.get('/pie-charts', {
					params: filters
				})
				.then((response) => {
					this.chartData['registrarAbuse'] = response.data['registrarAbuse'];
					this.chartData['tldAbuse'] = response.data['tldAbuse'];
					this.dataFetched['charts'] = true;
				})
				.catch((error) => {
					this.dataFetched['charts'] = true;
					console.log(error)
				});
			},
			populateDataSummary(type, val) {
				this['chartData']['dataSummary'][type] = val;
			},
			refreshPage() {
				this.populateDashboardData();
				this.isFilterApplied = false;
			},
			getDeviationColor(data, direction) {
				switch (data) {
					case 'down':
						return direction == 'alternate' ? 'success' : 'danger';
					case 'none':
						return 'primary';
					default:
						return direction == 'alternate' ? 'danger' : 'success';
				}
			},
			viewAnomaly(data, type) {
				// Store filter value in store
				this.$store.dispatch("setAnomalyFilter", data);
				// Redirect to listing
				switch (type) {
					case 'source':
						this.$router.push({ path: '/abuse-reported?filter=true' })
						break;
					case 'registration':
						this.$router.push({ path: '/new-registrations?filter=true' })
						break;
					default:
						this.$router.push({ path: '/new-registrations?filter=true' })
						break;
				}
			},
			filterTLD(tld) {
				// Store filter value in store
				this.$store.dispatch("setAnomalyFilter", {
					tld: tld
				});
				// Redirect to listing
				this.$router.push({ path: '/abuse-reported?filter=true' })
			},
			filterAbuseReport(type) {
				// Store filter value in store
				this.$store.dispatch("setAnomalyFilter", {
					status: type
				});
				// Redirect to listing
				this.$router.push({ path: '/abuse-reported?filter=true' })
			},
			filterProactiveTakedowns() {
				// Store filter value in store
				this.$store.dispatch("setAnomalyFilter", {
					source: {
						label: "Proactive Analysis",
						value: 17
					}
				});
				// Redirect to listing
				this.$router.push({ path: '/abuse-reported?filter=true' })
			},
		},
		beforeMount() {
			// Dashboard Data AXIOS Call
			this.populateDashboardData();
		},
		mounted() {
			// Clear Anomaly Filter
			this.$store.dispatch("clearAnomalyFilter");
			// Populate Data Summary Chart
			this.populateDataSummary('newRegistrations', this.dashboardStats['totalRegistrations']['graph']);
			this.populateDataSummary('abuseReported', this.dashboardStats['abuseReport']['graph']);
			this.populateDataSummary('abuseRegistrations', this.dashboardStats['abuseRate']['graph']);
			// Register Filter Event Listeners
			this.$root.$on('applyGlobalFilters', (val) => {
				this.populateDashboardData(val);
				this.isFilterApplied = true;
				this.filtersApplied = val;
			});
			this.$root.$on('resetGlobalFilters', () => {
				this.populateDashboardData();
				this.isFilterApplied = false;
				this.filtersApplied = null;
			});
		},
		beforeDestroy() {
			// Hide Global Filters
			this.$root.$emit('hideGlobalFilters');
		},
		destroyed() {
			// Unbind Filter Event Listeners
			this.$root.$off('applyGlobalFilters');
			this.$root.$off('resetGlobalFilters');
		}
	}
</script>

<style lang="scss">
#dashboard-analytics {
	.apexcharts-yaxis {
		display: none !important;
	}
}
</style>